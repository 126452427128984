// Swatch
$product-swatches-grouped-swatch-margin: rem-calc(0 6 10);
$product-swatches-grouped-group-display--large: contents;
$product-swatches-grouped-group-flex-wrap--large: wrap;
$product-swatches-grouped-group-flex-direction--large: row;

// Swatches Grouped Toggle
$product-swatches-grouped-toggle-secondary-padding: rem-calc(15 0 0 0);
$product-swatches-grouped-toggle-secondary-font: 300 #{rem-calc(12)} / rem-calc(18) $font-primary;
$product-swatches-grouped-toggle-secondary-font--large: $product-swatches-grouped-toggle-secondary-font;

// Swatches Group
$product-swatches-grouped-group-margin: 0;
$product-swatches-grouped-tab-font: 300 #{rem-calc(12)} / #{rem-calc(18)} $font-primary;
$product-swatches-grouped-tab-color: color(primary);
$product-swatches-grouped-tab-border-radius: 0;
$product-swatches-grouped-tab-padding: rem-calc(6 10 4);
$product-swatches-grouped-tab-padding--large: $product-swatches-grouped-tab-padding;
$product-swatches-grouped-tabs-control-items-margin: rem-calc(30 0 15);
$product-swatches-grouped-tab-margin: rem-calc(0 10 0 0);
$product-swatches-grouped-tab-first-margin: rem-calc(0 10 0 0);
$product-swatches-grouped-tabs-content-margin: rem-calc(15 0 5);

@import "@lora/05-components/product/product-swatches-grouped";