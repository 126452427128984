
                            $is-app_autoreplenishment-enabled: true;
                            $replenishment-selector-item-background: transparent;
$replenishment-selector-label-font: 600 #{rem-calc(14)} / rem-calc(20) $font-primary;
$replenishment-selector-item-padding: rem-calc(7);

// Price
$replenishment-selector-price-old-margin: rem-calc(0 10 0 0);

// Item
$replenishment-selector-item-padding: rem-calc(16);
$replenishment-selector-item-padding--large: $replenishment-selector-item-padding;

@import "@root/05-components/replenishment-selector";
                        