// Product Name
$product-main-v2-name-font: bold #{rem-calc(32)} / 1 $font-tertiary;
$product-main-v2-name-font--large: bold #{rem-calc(50)} / 1 $font-tertiary;
$product-main-v2-name-margin--large: rem-calc(0 0 5);

// Product Subtitle
$product-main-v2-subtitle-font: normal #{rem-calc(14)} / 1.4 $font-primary;
$product-main-v2-subtitle-text-transform: uppercase;
$product-main-v2-subtitle-text-transform--large: $product-main-v2-subtitle-text-transform;
$product-main-v2-subtitle-font--large: #{rem-calc(14)} / rem-calc(22) $font-primary;
$product-main-v2-info-text-align: left;
$product-main-v2-info-text-align--large: $product-main-v2-info-text-align;
$product-main-v2-subtitle-margin--large: 0;
$product-main-v2-subtitle-color: color(dark);
$product-main-v2-subtitle-color--large: $product-main-v2-subtitle-color;

//Product Price
$product-main-v2-price-font: 500 #{rem-calc(14)} / rem-calc(20) $font-secondary;
$product-main-v2-price-font--large: $product-main-v2-price-font;
$product-main-v2-price-padding: 0;
$product-main-v2-price-padding--large: $product-main-v2-price-padding;
$product-main-v2-price-border-bottom: 0;
$product-main-v2-price-border-bottom--large: $product-main-v2-price-border-bottom;

//Rating
$product-main-v2-rating-justify-content: left;
$product-main-v2-rating-justify-content--large: left;

// Variation Section
$product-main-v2-variation-section-margin--large: rem-calc(0 0 25);

$product-main-v2-estimated-delivery-date-color: color(dark);
$product-main-v2-availability-label-font--large: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$product-main-v2-availability-label-text-transform: none;

@import "@lora/05-components/product/product-main-v2";