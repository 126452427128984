$product-sticky-bar-title-font-size: rem-calc(18);
$product-sticky-bar-title-font-weight: 500;
$product-sticky-bar-title-font-family: $font-primary;
$product-sticky-bar-title-text-transform: uppercase;
$product-sticky-bar-item-image-border: none;
$product-sticky-bar-letter-spacing: rem-calc(1);
$product-sticky-bar-description-text-transform: uppercase;
$product-sticky-bar-wishlist-text-transform: uppercase;
$product-sticky-bar-wishlist-link-font-size: rem-calc(12);
$product-sticky-bar-wishlist-link-font-weight: 500;
$product-sticky-bar-item-variation-dropdown-width: 85%;
$product-sticky-bar-availability-label-text-transform: none;

@import "@lora/05-components/product/product-sticky-bar";